.table-wrapper td::first-letter,
.table-wrapper .button::first-letter {
    text-transform: uppercase;
}

.ui.table thead th.sortable-table {
    cursor: pointer;
}

.pagination-container {
    width: 100%;
    margin: 1rem 0 0;
    text-align: center;
}
.pagination-container.top {
    margin: 0 0 1rem 0;
    padding-top: 1rem;
    border-top: 1px solid #eee;       
}

.table-wrapper .collapsable td:first-child {
    cursor: pointer;
}

.table-wrapper .collapsable.open td:first-child {
    cursor: auto;
}

.table-wrapper .collapsable.open div:first-child {
    cursor: pointer;
}

.table-wrapper .collapsable.open svg {
    margin-right: 0.2rem;
}

.table-wrapper .collapsable.open svg .fa-secondary {
    opacity: 0.2;
}
    
.table-wrapper .collapsable .collapsed td {
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-wrapper .collapsable .open .header {     
    font-weight: bold;
}
.table-wrapper .collapsable .open .header div {
    margin-bottom: 0.5rem;
}

.table-wrapper .collapsable .open .header div:last-child {
    margin-bottom: 0;
}
