* {
    font-family: Lato, sans-serif;
}

body, html, #root {
    height: 100%;
}

/* headings */

h1, h2, h3 {
    color: #000;
}
.app-page h1 {
    font-size: 1.1rem;
}

.app-page h2 {
    font-size: 1.0rem;
    margin-bottom: 0.5rem;
}

/* links */

.app-page a, .app-page a:visited, .navigation a, .navigation a:visited {
    color: #23318E;
}
.app-page a:hover, .app-page a:active, .navigation a:hover, .navigation a:active {
    color: #255CAE;
}

.app-page .ui.list .list>a.item, .app-page .ui.list>a.item {
    color: #23318E;
}

.subnavilink {
    padding-left: 20px;
}

a.header.item.close:after {
    content:'';
    float:right;
    width: 0; 
    height: 0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #666;
    margin-top: 5px;
}
a.header.item.open:after {
    content:'';
    float:right;
    width: 0; 
    height: 0; 
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #666;
    margin-top: 5px;
}
.last-in-group {
    border-bottom: 1px solid #AAA;
}

/* header in list */
.app-page .ui.list header.item {
    font-weight: bold;
}

/* buttons */

button.ui.button, button.ui.button:hover, button.ui.button:active {
    background: #EDE8C6;
    color: #000;
    margin-bottom: 5px;
}

/* layout */

.app-header {
    border-bottom: 5px solid #EDE8C6;
}

.app-page {
    margin: 20px 0;
}

.app-page .ui.segment {
    box-shadow: none;
    border: none;
    margin: 0 0 1rem 0;
    padding: 1em 1em;
}

.app-page .ui.segment.title {
    margin: 0;
}

.ui.segment.pushable {
    border: none;
    box-shadow: none;
}

/* Loading */

#loader .dimmer {
    background-color: rgba(230,230,230,0.7);
    position: fixed;
}
#loader .ui.dimmer .ui.loader:before {
    border-color: #255CAE;
}


/* Navigation */
.navigation {
    background: transparent;
}
.navigation .ui.menu {
    border: none;
    box-shadow: none;
    background: transparent;
}
.navigation .ui.menu .item:before {
    width: 0px;
}
.navigation .ui.menu .item {
    padding-top: 0px;
    padding-bottom: 0px;
    vertical-align: bottom;
}
.ui.menu a.item {
    color: #4183c4;
}
.ui.menu a.item.header {
    color: rgba(0,0,0,.87)
}
.navigation .left.menu .item {
    padding-left: 0; 
}
.navigation .navi2 {
    margin-top: 40px;
}

.navigation .ui.menu .dropdown.item:hover {
    background: transparent;
}

/* Subnavigation */
.subnavigation {
    margin-bottom: 20px;
}
.subnavigation .ui.secondary.pointing.menu .active.item {
    border-color: #23318E;
}
.subnavigation a.disabled-link {
    color: #999;
}

/* Mobile navigation */

.mobile-navigation .ui.vertical.menu .dropdown.item .menu {
    right: 0;
    left: auto;
    top: 40px;
}

/* Greeting */

.greeting {
    margin-bottom: 10px;
}
.aforism {
    margin: 10px 0 40px;
    padding-left: 20%;
    font-style: italic;
    letter-spacing: 0.1rem;
}

/* hide element */
.hidden {
    visibility: hidden;
}

/* Misc */

/* asterisk for required fields */
i.asterisk.icon::before {
    color: #E50053;
    margin-left: 5px;
    font-size: 0.5rem;
}
.highlight {
   color: #E50053;
}

.small-text {
    width: 100px;
}

/* info buttons */
.ui.form .field.float-left {
    display: inline-block;
}
i.info.icon::before {
    /*color: #23318E;*/
    margin-left: 10px;
}

/* indented input groups */
.ui.form .field.indented {
    margin-left: 1.8rem;
}
.ui.segment.indented {
    margin-left: 1.8rem;
}

.noDialogueContainer {
    margin-top: 10rem;
}

/* search form for user list */
.user-search {
    margin-bottom: 20px;
}

.ui.container.app-page {
    min-height: 600px;
}

/* shift list */
.shiftlist {
    margin-top: 10px;
    margin-bottom: 20px;
}
.min300 {
    min-height: 300px;
}
.min600 {
    min-height: 600px;
}
.shift-item {
    margin: 20px 0px;
}

/* subtitle for questions */
.with-subtitle h1 {
    margin-bottom: 0px;
}
.without-subtitle h1:last-child {
    margin-bottom: 1rem;
}
.subtitle {
    font-style: italic;
    margin: 0 0 1rem 0;
}

/* single checkbox questions */
.ui.form .field.single-checkbox {
    margin: 3rem 0;
}

/* modals */

.ui.page.dimmer {
    background-color: rgba(200,200,200,0.9);
}

/* validation error */
.validation-error{
    color: red;
}

.persons-on-call {
    margin-bottom: 20px;
}

/* full width buttons in paged tables */

.shiftlist table button {
    width: 100%;
}


/* DO NOT REMOVE THESE OR THE MOBILE NAVIGATION BREAKS */
.pushable:not(body).patch { 
    -webkit-transform: unset; transform: unset;
}

.pushable:not(body) .patch.ui.sidebar {
    position: fixed;
}

body > div {
    z-index: 100;
}


/* colors for icons */
.mll-blue {
    color: #255CAE;
}
.mll-pink {
    color: #E50053;
}
.mll-turqoise {
    color: #21C5E4;
}
.mll-yellow {
    color: #FCFF85;
}
.mll-green {
    color: #B3DB60;
}
.mll-black {
    color: #000000;
}
.mll-white {
    color: #FFFFFF;
}
.mll-beige {
    color: #EDE8C6;
}

.ui.grid.small-padding>.row {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.ui.grid.add-margin {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2.add-margin-right {
    margin-right: 40px;
}

.full-width {
    width: 100%;
}

.ui.message.align-center {
    text-align: center;
}

.float-right {
    float: right;
}

/* makes disabled elements more visible*/
.PageContainer .ui.form .disabled.field, .PageContainer .ui.form .disabled.fields .field, .PageContainer .ui.form .field :disabled {
    opacity: 1.0;
}

.button.fixed-width {
    min-width: 120px;
}